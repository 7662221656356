import axios from 'axios';
import {useToast} from 'vue-toast-notification';
const $toast = useToast();

axios.defaults.headers.common = {'Authorization': `bearer ${getUserToken()}`}

const texts = {
    "Complete your configuration":"Complétez votre configuration",
    "Something went wrong":"Quelque chose n'a pas fonctionné",
    "Customized SMS":"SMS personnalisés",
    "The name that you want to send your SMS with to your customers.":"Le nom avec lequel vous voulez envoyer votre SMS aux clients.",
    "Sender Name (Max: 10 characters)":"Nom de l'expéditeur (10 caractères maximum)",
    "Submit":"Soumettre",
    "Automated WhatsApp Messaging":"Messagerie WhatsApp automatisée",
    "The professional name to appear on your account.":"Le nom professionnel qui apparaîtra sur votre compte.",
    "Sender Name":"Nom de l'expéditeur",
    "Sender Phone Number":"Numéro de téléphone de l'expéditeur",
    "The phone number to send the WhatsApp message with":"Le numéro de téléphone avec lequel envoyer le message WhatsApp",
    "Note: To change the WhatsApp number and attach your WhatsApp number for automated messages, contact Shoppy MarketSpace.":"Notez : Pour changer le numéro WhatsApp et joindre votre propre numéro WhatsApp pour les messages WhatsApp automatisés, contactez Shoppy MarketSpace.",
    "Sender Email":"Email de l'expéditeur",
    "The address to send the email with":"L'adresse à utiliser pour l'envoi du courrier électronique",
    "Complete Configuration":"Compléter la configuration",
    "You have nothing to configure here. You can click the botton below to add this platform.":"Vous n'avez rien à configurer ici. Vous pouvez cliquer sur le bouton ci-dessous pour ajouter cette plateforme.",
    "All fields are obligatory!":"Tous les champs sont obligatoires !",
    "You have successfully added this platform. Please go back to continue.":"Vous avez ajouté cette plate-forme avec succès. Veuillez revenir en arrière pour continuer.",
    "Configuration Successful!":"Configuration réussie !",
    "You need atleast 50 subscribers to access this section. This is because we need as much data from the activity of your shop as possible.":"Vous devez avoir au moins 50 abonnés pour accéder à cette section.  En effet, nous avons besoin d'un maximum de données sur l'activité de votre boutique."
}
function defineBaseUrl(){
  
    axios.defaults.baseURL = getQueryParam("_base"); // 'http://localhost:40505/api/v2/my.store'
}

function translate($text){
    return getLocale()=="en"? $text:texts[$text]??$text;
}

function getQueryParam($param){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get($param);
}
function getLocale(){
    return getQueryParam('lang')??"fr";
}
function getUserToken(){
    return getQueryParam('_t')??null;
}
function toast(message,type="error"){
  $toast.open({
        message:message,
        type:type
    });
}
function get_request(url,params,onSuccess=()=>{},onFinally=()=>{},onFailure=()=>{}){
    
    axios.get(url,{params: params}).then(r=>{
        console.log(r.data.data);
        onSuccess(r.data.data);
    }).catch(error=>{
        if(error.response && error.response.data && error.response.data.data){
            toast(error.response.data.data);
        }else{
            toast(translate("Something went wrong"))
        }
        console.error(error);
        onFailure(error);
    }).finally(()=>{
        onFinally();
    })
}

function post_request(url,params,onSuccess=()=>{},onFinally=()=>{},onFailure=()=>{}){
    
    axios.post(url,params).then(r=>{
        console.log(r.data.data);
        onSuccess(r.data.data);
    }).catch(error=>{
        if(error.response && error.response.data && error.response.data.data){
            toast(error.response.data.data);
        }else{
            toast(translate("Something went wrong"))
        }
        console.error(error);
        onFailure(error);
    }).finally(()=>{
        onFinally();
    })
}
defineBaseUrl();
export {
    translate,
    get_request,
    post_request,
    toast
}
