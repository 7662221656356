<template>
    <div>
       <div class="ui container" style="padding: 5% ;" >
            <!-- <button class="ui primary button">
            Save
            </button> -->
            <!-- <div class="ui segment"> -->
                <h4 style="margin-bottom: 0;" class="color-mystore-primary">{{ translate("Configuration") }}</h4>
                <!-- <h1 style="margin-top: 0;" class="color-mystore-primary">{{ translate("Automated Emails") }}</h1> -->
                <div class="ui divider"></div>
                <br/>
                <div class="ui segment" >
                    <div class="row">
                        <!-- <i class="fa fa-solid fa-down" style="font-size: 20em;color:red"></i> -->
                        <!-- <p>{{ translate("The content here is not yet ready. Please come back later") }}</p> -->
                        <p>{{ translate("You need atleast 50 subscribers to access this section. This is because we need as much data from the activity of your shop as possible.") }}</p>
                    
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
    
</template>
<script>

import {translate/*,get_request,post_request,toast*/} from '@/misc/helpers'
export default{
    methods:{
        translate:translate,
    },
    data(){
        return {
            senderId:"",
            fetching:true,
            completed:false
        }
    },
    mounted(){
    
    }
}
</script>

<style scoped>

    
</style>