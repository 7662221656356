<template>
    <div>
       <div v-if="!completed">
        <div class="ui container" style="padding: 5% ;" v-if="$route.params.platform=='SMS'">
            <!-- <button class="ui primary button">
            Save
            </button> -->
            <!-- <div class="ui segment"> -->
                <h4 style="margin-bottom: 0;">{{ translate("Complete your configuration") }}</h4>
                <h1 style="margin-top: 0;" class="color-mystore-primary">{{ translate("Customized SMS") }}</h1>
                <div class="ui divider"></div>
                <br/>
                <form class="ui form" >
                    <div class="field">
                        <label>{{ translate("Sender Name (Max: 10 characters)") }}</label>
                        <span style="opacity:0.8;margin-bottom:10px;">{{ translate("The name that you want to send your SMS with to your customers.") }}</span>

                        <div class="fields" style="margin-top:10px;">
                        <div class="sixteen wide field">
                            <input maxlength="10" max="10" type="text" v-model="senderId" :placeholder="translate('Sender Name (Max: 10 characters)')" :disabled="fetching">
                        </div>
                        </div>
                    </div>
                    <br/>
                    <div @click="completeConfig" class="ui button" :class="`${fetching?'loading':''}`" tabindex="0" style="background-color: var(--mystore-primary); width:100%;color:white;float:right" :disabled="fetching">{{ translate("Complete Configuration") }}</div>
                </form>
            <!-- </div> -->
        </div>

        <div class="ui container" style="padding: 5% ;" v-else-if="$route.params.platform=='WHATSAPP'">
            <!-- <button class="ui primary button">
            Save
            </button> -->
            <!-- <div class="ui segment"> -->
                <h4 style="margin-bottom: 0;">{{ translate("Complete your configuration") }}</h4>
                <h1 style="margin-top: 0;" class="color-mystore-primary">{{ translate("Automated WhatsApp Messaging") }}</h1>
                <div class="ui divider"></div>
                <br/>
                <form class="ui form" >
                    <div class="field">
                        <label>{{ translate("Sender Name") }}</label>
                        <span style="opacity:0.8;margin-bottom:10px;">{{ translate("The professional name to appear on your account.") }}</span>

                        <div class="fields" style="margin-top:10px;">
                        <div class="sixteen wide field">
                            <input type="text" value="Shoppy MarketSpace" placeholder="Shoppy MarketSpace" :disabled="true">
                        </div>
                        </div>
                    </div>
                    <div class="field">
                        <label>{{ translate("Sender Phone Number") }}</label>
                        <span style="opacity:0.8;margin-bottom:10px;">{{ translate("The phone number to send the WhatsApp message with") }}</span>

                        <div class="fields" style="margin-top:10px;">
                        <div class="sixteen wide field">
                            <input type="text"  :placeholder="+237659462121" :disabled="true" value="+237659462121">
                        </div>
                        </div>
                    </div>
                    <small class="color-mystore-primary">{{ translate("Note: To change the WhatsApp number and attach your WhatsApp number for automated messages, contact Shoppy MarketSpace.") }}</small>
                    <br/>
                    <br/>
                    <div class="row">
                        <div @click="completeConfig" class="ui button" :class="`${fetching?'loading':''}`" tabindex="0" style="background-color: var(--mystore-primary); width:100%;color:white;float:right" :disabled="fetching">{{ translate("Complete Configuration") }}</div>
                    </div>
                </form>
            <!-- </div> -->
        </div>

        <div class="ui container" style="padding: 5% ;" v-else-if="$route.params.platform=='EMAIL'">
            <!-- <button class="ui primary button">
            Save
            </button> -->
            <!-- <div class="ui segment"> -->
                <h4 style="margin-bottom: 0;">{{ translate("Complete your configuration") }}</h4>
                <h1 style="margin-top: 0;" class="color-mystore-primary">{{ translate("Automated Emails") }}</h1>
                <div class="ui divider"></div>
                <br/>
                <form class="ui form" >
                    <div class="field">
                        <label>{{ translate("Sender Name") }}</label>
                        <!-- <span style="opacity:0.8;margin-bottom:10px;">{{ translate("The name to appear.") }}</span> -->

                        <div class="fields" style="margin-top:10px;">
                        <div class="sixteen wide field">
                            <input type="text" v-model="senderName" :placeholder="senderName" :disabled="true">
                        </div>
                        </div>
                    </div>
                    <div class="field">
                        <label>{{ translate("Sender Email") }}</label>
                        <span style="opacity:0.8;margin-bottom:10px;">{{ translate("The address to send the email with") }}</span>

                        <div class="fields" style="margin-top:10px;">
                        <div class="sixteen wide field">
                            <input type="text"  :placeholder="senderId" :disabled="true" v-model="senderId">
                        </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div @click="completeConfig" class="ui button" :class="`${fetching?'loading':''}`" tabindex="0" style="background-color: var(--mystore-primary); width:100%;color:white;float:right" :disabled="fetching">{{ translate("Complete Configuration") }}</div>
                    </div>
                </form>
            <!-- </div> -->
        </div>

        <div class="ui container" style="padding: 5% ;" v-else>
            <!-- <button class="ui primary button">
            Save
            </button> -->
            <!-- <div class="ui segment"> -->
                <h4 style="margin-bottom: 0;">{{ translate("Complete your configuration") }}</h4>
                <!-- <h1 style="margin-top: 0;" class="color-mystore-primary">{{ translate("Automated Emails") }}</h1> -->
                <div class="ui divider"></div>
                <br/>
                <div class="ui segment" >
                    <div class="row">
                        <!-- <i class="fa fa-solid fa-down" style="font-size: 20em;color:red"></i> -->
                        <p>{{ translate("You have nothing to configure here. You can click the botton below to add this platform.") }}</p>
                        <div @click="completeConfig" class="ui button" :class="`${fetching?'loading':''}`" tabindex="0" style="background-color: var(--mystore-primary); width:100%;color:white;float:right" :disabled="fetching">{{ translate("Complete Configuration") }}</div>
                        <br/>
                        <br/>
                    </div>
                </div>
            <!-- </div> -->
        </div>
       </div>
       <div class="ui container" style="padding: 5% ;" v-else>
            <!-- <button class="ui primary button">
            Save
            </button> -->
            <!-- <div class="ui segment"> -->
                <h4 style="margin-bottom: 0;" class="color-mystore-primary">{{ translate("Configuration Successful!") }}</h4>
                <!-- <h1 style="margin-top: 0;" class="color-mystore-primary">{{ translate("Automated Emails") }}</h1> -->
                <div class="ui divider"></div>
                <br/>
                <div class="ui segment" >
                    <div class="row">
                        <!-- <i class="fa fa-solid fa-down" style="font-size: 20em;color:red"></i> -->
                        <p>{{ translate("You have successfully added this platform. Please go back to continue.") }}</p>
                    
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
    
</template>
<script>

import {translate,get_request,post_request,toast} from '@/misc/helpers'
export default{
    methods:{
        translate:translate,
        completeConfig(){
            const params  =this.$route.params;
            if(params.platform=="SMS" && (!this.senderId||this.senderId.trim()=="")){
                toast(translate("All fields are obligatory!"));
                return;
            }else if(params.platform=="SMS" && this.senderId.length>10){
                toast("A maximum of 10 characters are required");
                return;
            }
            
            this.fetching=true;
            post_request(`/campaign/config/${params.campaignId}/${params.platform}`,{...this.$data},()=>{
                this.completed=true;
            },()=>{
                this.fetching=false;
            },()=>{

            });
        }
    },
    data(){
        return {
            senderId:"",
            fetching:true,
            completed:false
        }
    },
    mounted(){
        // cUc8fpoq0J6DIWnscBOAzmvwHcIcrIinQMRcZPDGCcBUeGsJarPH0PHx4ZI9xvVpYRad5m5eizqlEojQDXFDYly4k4fqyl09rzRJCqQfhyoMiWY1YMZTicfKmuxVXf7oUj0K8WiwDXOlcopNxW0RF6F62Uih2h70PFMe1Gr5dQDwxOGNzaR16VqkT6OVfJ1UDHWZ1f4FxI850UjPifbdpeXInFGJMFB9MsUWKi4waZ51DcTE50hyyHc9fO
        const params  = this.$route.params;
        get_request(`/campaign/config/${params.campaignId}/${params.platform}`,{},(r)=>{
            Object.keys(r).forEach(y=>{
                this[y]=r[y]
            })
        },()=>{
            this.fetching=false;
        },()=>{

        });
    }
}
</script>

<style scoped>

    
</style>