import { createWebHistory, createRouter } from "vue-router"
import ConfigureCampaigns from "@/views/campaigns/ConfigureCampaigns.vue"
import CompleteConfigureCampaigns from "@/views/campaigns/CompleteConfigureCampaigns.vue"

const routes = [
  {
    path: "/my.store/campaign/config/:campaignId/:platform",
    name: "ConfigureCampaigns",
    component: ConfigureCampaigns,
  },
  {
    path: "/my.store/campaign/complete-config/:campaignId/",
    name: "ConfigureCampaigns",
    component: CompleteConfigureCampaigns,
  },
]

const router = createRouter({
    history: createWebHistory(),
    routes
  })

export default router