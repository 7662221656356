<template>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  methods:{
    closeInApp(){
      window.closeInAppWindow();
    }
  }
}
</script>

<style>

</style>
